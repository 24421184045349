import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/BlogMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Question which gets asked too often is `}<em parentName="p">{`"When you say programmable proxy, what is a programmable proxy, and why do you need a programmable proxy?"`}</em>{`. This article tries to answer this question from different perspective. We will start with brief definition of what is a proxy? then move forward our discussion of evolution, proxy went through its development process, explaining the need for those stages and what benefits each stage provide and why such stage improvements were required. In end we will discuss several aspects of `}<em parentName="p">{`"programmability"`}</em>{` and provide a summary of "why we need programmable proxy".`}</p>
    <p>{`A proxy server is usually deployed in the middle of two isolated networks where it has access to both sides of the network and transfers data from one side to the other to achieve network connectivity. Proxy is a kind of serial network device, which has existed since the birth of computer networks. Proxy acts not only as a network connector, but it also opens a path to new functionality and usage scenarios like:`}</p>
    <ul>
      <li parentName="ul">{`Routing. The proxy forwards data to different destinations according to the characteristics of the data defined.`}</li>
      <li parentName="ul">{`Load balancing. During forwarding, data is distributed to different destinations to improve throughput and avoid destination single point of failure. Load balancing is becoming an area of proxy segmentation functionality`}</li>
      <li parentName="ul">{`Fail over. In the forwarding process, when the destination fails, the proxy can forward the data to an alternate destination, providing uninterrupted service to the requester`}</li>
      <li parentName="ul">{`Access control. Proxy can decide what traffic is allowed through and what needs to be blocked. Web Application Firewall (WAF) is a typical proxy application example`}</li>
      <li parentName="ul">{`Identity management. Access control is often based on identity information, so proxies often have identity management capabilities as well`}</li>
      <li parentName="ul">{`Network acceleration. proxy accelerates network access by caching data`}</li>
      <li parentName="ul">{`Metrics collection. Proxy captures data statistics and summarizes it to NPM software for network optimization and network planning`}</li>
      <li parentName="ul">{`Information security. In addition to access control, proxies can also be used for security auditing, TLS/SSL offloading, and data encryption to meet security requirements`}</li>
    </ul>
    <p>{`In addition to proxying, routers provide the functionality of bridging two networks. Routers work at layer 3 of the network while Proxies work at layers above 3, like  layer 4 and layer 7.`}</p>
    <p>{`Proxy servers have gone through following evolutions during its development:`}</p>
    <ol>
      <li parentName="ol">{`Configurations file era. Proxy software (mostly open source software), which makes up the majority of network infrastructure software, provides different functions in different domains, such as proxy for different protocols, proxy for load balancing, proxy for cache acceleration. This generation of proxy is all configuration based. User sets parameters, configures rules in the configuration file, and then starts the service process to execute the rules`}</li>
      <li parentName="ol">{`Configuration DSL. Static configuration files are difficult to express complex logic, so many proxies introduced thin scripting capabilities on top of configuration, commonly referred to as `}<em parentName="li">{`"configuration languages"`}</em>{` or Domain specific languages (DSL for short), such as Haprxoy's ACL or Varnish's VCL`}</li>
      <li parentName="ol">{`Scripting language era. As the logic becomes more complex, it becomes more difficult to express that via configuration languages; At the same time, when the number of configuration languages reaches a certain extent, the management of configuration languages itself becomes difficult. Shell scripts, for example, one can write simple logic, but when the shell code reaches a certain level, it is often a step toward more structured scripting languages like Perl or Python. Proxy supports scripting language, which has both the convenience of scripting language and the structural advantage of programming language. Examples of this are OpenResty (Nginx + Lua) and Nginx Plus(Nginx + NJS). These examples also include proxy servers implemented by a number of application programming languages, such as Node.js based StrongLoop and Spring Gateway, which often have scripting capabilities of their own`}</li>
      <li parentName="ol">{`Cluster age. Script language solves the difficulties in modularization and structuring of complex logic in proxy server. A further requirement at this point is to integrate proxies with other administrative control tools, hence the need for a REST or alike interface. The external control plane can dynamically update the logic in the script through the REST or alike interface. At the same time, the use of proxies has moved from single instances to cluster of proxies, so proxy software like Envoy and OpenResty based Kong often support clustering capabilities themselves, implementing clustering capabilities in some centralized or shared way, while providing REST interfaces. For proxies of type #4 mentioned above, cluster management is generally possible through configuration management; Configuration management tools can also expose REST interfaces. For example, the Ansible + Nginx solution implements similar capabilities to #5. In contrast, scheme #4 requires more components to form the scheme, while scheme #5 is more convergent`}</li>
      <li parentName="ol">{`Cloud era. In this era, proxies are deployed in a distributed manner. The most common scenario is to deploy one proxy for each application process e.g Sidecar Proxy pattern. Adoption of distributed proxies, opened the doors for using different rules and policies for different upstream services, that is, multi-tenant capability. The various upstream services not only have logically independent rules and policies; Physical isolation is also  provided, allowing granular management at the process and interface level. If we consider the control plane and data plane of the service mesh as a whole, then the service mesh is representative of this domain, typical examples are Istio+Envoy, Linkerd + Linkerd proxy. `}<a parentName="li" {...{
          "href": "https://flomesh.io"
        }}>{`Pipy`}</a>{` is a product of this age and falls under this category.`}</li>
    </ol>
    <p>{`In the above evolution stages, each stage is an improvement over the previous one:`}</p>
    <ul>
      <li parentName="ul">{`#2 adds basic scripting capabilities over #1. This basic scripting capability is augmented by dynamic capabilities on top of configuration files. For example, get the characteristics of the request at run time (such as HTTP headers), and then make dynamic logical decisions based on those characteristics to perform specific actions`}</li>
      <li parentName="ul">{`#3 adds full scripting capabilities over #2, allowing scripting logic to be structured and modular. In the #2 stage, when logic becomes complex, the volume of scripts increases dramatically, and structured scripting capabilities become a necessity`}</li>
      <li parentName="ul">{`#4 has more REST interfaces and clustering capabilities than #3. To horizontally expand the proxy capacity, you need to cluster multiple proxy instances. Instances in the cluster share configurations and scripts, and users can manage configurations and scripts through the REST interfaces`}</li>
      <li parentName="ul">{`#5 has more distributed capabilities than #4, mainly because different instances within the same cluster run different scripts and configurations. In #4 mode, there are usually different configurations and policies for different upstream services, such as different authentication modes and access control mechanisms. As upstream services grow, the configurations of these different upstream services are logically separated but physically run in the same proxy process. This logically separated configuration and policy scripts running in the same physical process has some disadvantages: the more logic running in one process brings the more complexity; different upstream services share resources such as CPU and memory, affecting each other. If a script of an upstream service has a security vulnerability, the configurations of other upstream services may be leaked, resulting in security risks. #5 stage is an improvement over the #4 in that the proxy processes for each upstream service are independent and isolated from each other. They are managed by the same cluster manager, but the configuration and scripts at work are separated and isolated. This isolation is a strong requirement in a multi-tenant environment -- different upstream services belong to different tenants, and tenants should not affect each other or know each other's configurations. #5 can be thought of as the extreme mode of "#4 multi-clustering "-- at its most extreme, each process has its own configuration`}</li>
    </ul>
    <p>{`Lets take another look at the evolution of proxies -- `}<em parentName="p">{`The Evolution of Requirements`}</em>{`.`}</p>
    <ol>
      <li parentName="ol">{`The first generation of proxies mainly implemented proxy functionality and provided basic configurable capabilities. At that time, network equipment, especially Serial network equipment, required proxy to be highly reliable; The real-time transmission of massive data requires high throughput, low latency and low resources. Like all software, proxy also required to support modularity and extensibility. Proxy in this stage was mainly developed in C language, and the corresponding development of extension modules were also developed in C language. Extension modules get loaded dynamically at the start of the process. To summarize, proxy requirements at this stage are: `}<strong parentName="li">{`connectivity`}</strong>{` (network capabilities), `}<strong parentName="li">{`ease of use`}</strong>{` (configurable via configuration files), `}<strong parentName="li">{`reliability`}</strong>{` (requirements for cross-linking devices), `}<strong parentName="li">{`high performance`}</strong>{`, `}<strong parentName="li">{`scalability`}</strong></li>
      <li parentName="ol">{`The second generation of proxies got further improvements in extensibility and flexibility, such as some dynamic data acquisition and making logical judgment. The introduction of scripts further enhanced the `}<strong parentName="li">{`ease of use`}</strong>{`. Support for combinatorial logic and dynamic data retrieval provided `}<strong parentName="li">{`flexibility`}</strong>{` while improving `}<strong parentName="li">{`scalability`}</strong></li>
      <li parentName="ol">{`The main improvements of the 3rd generation proxies over the 2nd generation proxies are `}<strong parentName="li">{`manageability`}</strong>{`, `}<strong parentName="li">{`developer friendliness`}</strong>{` and `}<strong parentName="li">{`programmability`}</strong>{`. Scripting capabilities are widely used, mainly because it is difficult to develop and maintain extensions using C language, while scripting languages are easier to learn and provide fast turnarounds in developments when compared to compiled languages counterparts. Developers' development efficiency and the difficulty of massive script maintenance require this generation of proxies to use a more structured scripting language, and to maintain performance, resource utilization and other core capabilities of the previous generation. The use of structured and modular scripting language, opened the era of `}<strong parentName="li">{`programmable`}</strong>{` proxies and required proxies to provide two level of possibilities, one is to use C language for development of `}<em parentName="li">{`core modules`}</em>{`, and scripts to be used to program `}<em parentName="li">{`dynamic logic`}</em>{`; In other words `}<strong parentName="li">{`programmable`}</strong>{` includes the meaning of giving its user the power of development of `}<em parentName="li">{`core modules`}</em>{` and `}<em parentName="li">{`dynamic logic`}</em>{`.`}</li>
      <li parentName="ol">{`The fourth generation of proxies starts with cluster support capabilities, which are improvements to `}<strong parentName="li">{`manageability`}</strong>{`. With the support of REST interfaces, proxies become part of network infrastructure implementation, and a landing place for `}<em parentName="li">{`infra as code`}</em>{`. REST interface capabilities improved proxies `}<strong parentName="li">{`ability to be managed`}</strong>{` and considered a part of the ease of administration. `}<em parentName="li">{`External interfaces`}</em>{` are also an important feature of `}<strong parentName="li">{`programmable`}</strong>{`, and REST, as the most common form of interface, is also widely used in the proxy server world. At this point, `}<strong parentName="li">{`programmable`}</strong>{` consists of three layers: programmable `}<em parentName="li">{`core modules`}</em>{` as described in #3, programmable `}<em parentName="li">{`dynamic logic`}</em>{` , and programmable `}<em parentName="li">{`external interfaces`}</em>{`. The emergence of proxy server cluster reflects the change of `}<strong parentName="li">{`scalability`}</strong>{` from `}<em parentName="li">{`function expansion`}</em>{` to `}<em parentName="li">{`resource expansion`}</em>{`. The emergence of REST interfaces provides the technical foundation for further `}<strong parentName="li">{`self-service`}</strong>{` and `}<strong parentName="li">{`managed services`}</strong></li>
      <li parentName="ol">{`The evolution of the fifth generation of proxy is driven by the popularity and rapid development of cloud computing. The elasticity, self-service, tenant, isolation, and metering of the cloud require that the proxy service software have the best capability of the `}<strong parentName="li">{`cloud native`}</strong>{`. If the fourth generation of agents is for `}<em parentName="li">{`system administrators`}</em>{`, the fifth generation of agents is for `}<em parentName="li">{`cloud services`}</em>{`. While fully maintaining the characteristics of previous generations of proxy software, `}<strong parentName="li">{`Cloud Ready`}</strong>{` is further incorporated. With the expansion of cloud computing to the edge, the fifth generation of proxies are also toward the development of heterogeneous hardware, heterogeneous software, low energy consumption, so this generation of proxies began to show the ability to optimize the integration of `}<strong parentName="li">{`cloud and edge`}</strong>{`. The fifth generation of proxies in the `}<strong parentName="li">{`programmable`}</strong>{` further evolution, from the `}<em parentName="li">{`core module`}</em>{`, `}<em parentName="li">{`dynamic logic`}</em>{`, `}<em parentName="li">{`external interface`}</em>{`, increased `}<em parentName="li">{`cloud`}</em>{` ability; Supports distributed, multi-tenant, and metering. Metering is a derivative requirement of multi-tenancy, which requires isolation on the one hand, and resources that can be measured at the smallest possible granularity on the other`}</li>
    </ol>
    <p>{`Let's summarize the above discussion into a tabular format, with the first column identifying a specific requirement that the proxy meets; Header row represents proxies at different stages, with the typical examples or known software in parentheses. In each cell, we use `}<strong parentName="p">{`*`}</strong>{` to indicate whether such capabilities are available and to what extent (1-5 `}<em parentName="p">{`, 5 `}</em>{` for full support, and 1 * for basic support).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`SN`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Requirement`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Configuration (squid, httpd, nginx)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Configuration Language (varnish, haproxy)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Scripting support (nginx+lua, nginx+js)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Clustering (kong, envoy)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Cloud (istio+envoy, linkerd, pipy)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Remarks`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Connectivity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Connectivity in the cloud era began using kernel technologies such as iptables and ebpf; Previously, there was only user Space process mode`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`reliability`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reliability has always been the most important fundamental capability of proxy server`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`high performance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Performance includes throughput, latency, error rate, and deviation from the mean. Latency metrics P99, P999 and other. Early proxy software has a long tail effect, so the indicators above P99 are not as good as later software. Proxy with high-performance scripts often perform better than their predecessors when returning the same content. Agents using proactive technology are more stable (with less deviation from the mean) while providing the same performance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`flexibility`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Compared with the fourth generation, the fifth-generation proxies significantly enhances the multi-protocol support capability, so we give this generation a five-star evaluation. Moreover, the processing model of the fifth generation can adapt to various protocols and has universality, which is better than that of the fourth generation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`scalability`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Similar to `}<em parentName="td">{`Flexibility`}</em>{`, the 5th generation proxies supports multiple protocols in addition to core function extension development, 7-layer logic extension development, so we give it one star more than the 4th generation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hardware compatibility`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Proxies developed in C or C++ generally have better hardware compatibility and a more active community to migrate applications to new hardware architectures. Proxies developed using Rust, Go, and Lua have been relatively slow to migrate for hardware compatibility`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`system compatibility`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`system`}</em>{` mainly includes two aspects, one is the operating system, the other is the cloud platform. In terms of operating system compatibility, each generation of proxies is similar; However, in terms of cloud platform compatibility, both the fourth and fifth generation proxies have more fully considered and realized cloud compatibility. In contrast, the significant difference between the fifth generation and the fourth generation is the ability to support multi-tenant`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ease of management`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ease of management is a function of the system operations & administrator roles. The first and second generations mainly use configuration files, based on which the use of configuration management tools to achieve automatic and batch management. In the third generation, in addition to managing configuration files, we need to further manage source files. But in essence, there is no significant difference from the first and second generation of ease of management. The fourth generation provides REST interfaces, which greatly improves the ease of management. In addition to REST, the fifth generation usually provides a control plane for the cloud to manage proxies; It also provides multiple external interfaces to meet other management requirements, such as monitoring, auditing, and statistics`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ease of use`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The primary users of the first three generations of proxies are ops & sys administrators. In the fourth generation, administrators began to provide some functions to users, and the as-a-service mode began to appear. The fifth generation takes into account more user scenarios and provides more rent-oriented capabilities`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ease of development`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The development around the proxies includes two aspects, one is inside the proxy to achieve the functionality, another is outside the proxy to achieve the management ability of the proxy. The first three generations provide an interface for internal development; The latter two generations provide both internal and external interfaces. The significant improvement of the fifth generation compared with the fourth generation is the cloud interface`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`11.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`core interface is programmable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Each generation of proxies provides the ability to extend core interfaces, but these interfaces are too low-level and difficult to master`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`12.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`functionality extension is programmable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Providing the ability to extend functions more efficiently is part of the process that gets better with each generation of proxies. It is the core metric of programmable proxy`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`13.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`protocol extensions are programmable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The first three generations are mainly for single agreements, or fixed agreements. Starting with the fourth generation, users began to seek support for multiple protocols and custom protocols. In the fifth generation, protocol extension is considered as a core capability in the design`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`14.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`modular scripting`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Third generation proxies are beginning to pay more attention to script structuring; The fourth and fifth generations attempt to prepare for more structured programming, such as Envoy's attempt to provide multilingual support through WASM; Pipy introduces high-performance JS scripts for better structuring`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`configuration management is programmable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The first three generations of proxy configuration are mainly for ops management personnel, and external configuration management tools are based on this premise. The fourth generation supports REST management interfaces. The fifth generation further provides a standard cloud interface for configuration management`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`16.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`resource extensions are programmable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In the first three generations, proxy capacity expansion is mainly to increase the number of threads or processes. The fourth generation provides scale-out capability for processes, known as clustering capability. On the basis of the fourth generation, the fifth generation on the one hand provides horizontal expansion capabilities, on the other hand provides capabilities under smaller resources to support more fine-grained metering and billing; That is, it not only supports incremental scaling, but also provides the ability to reduce scaling, and all of these capabilities provide programming interfaces`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tenant extension programmable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`* * *`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The cloud is something that has emerged at the same time as the fourth generation of proxy, and `}<em parentName="td">{`tenant`}</em>{`, as a core feature of the cloud, is not well supported in the fourth generation. The fifth generation is designed on the premise of cloud, considering and providing tenants with the possibility of programming their own extensions`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`rows #11 to #17 in the table above are the specific aspects of `}<strong parentName="p">{`Programmable Proxy`}</strong>{`. These aspects also constitute the answer to the question of `}<strong parentName="p">{`Why Programmable Proxy`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`The internal functions of the proxy need to be expanded, including the extension of the underlying core capabilities, the extension of supporting more protocols, and Layer7 processing capabilities (forwarding, routing, judgment, access control, etc.); These Layer7 processing power require a more convenient way of programming, that is, scripting and structured programming ability`}</li>
      <li parentName="ul">{`Proxies need to provide interfaces externally to integrate into larger management systems (such as cloud platforms), including configuration management, resource management, and so on`}</li>
      <li parentName="ul">{`Proxies need to provide extensible capabilities for different roles, including operations, administrators, resource providers, and tenants, all of which require the capability of `}<strong parentName="li">{`Programmable`}</strong>{` to some extent`}</li>
      <li parentName="ul">{`Also, like any `}<strong parentName="li">{`programmable`}</strong>{` component, `}<strong parentName="li">{`Programmable Proxy`}</strong>{` requires accompanying documentation, development manuals, code management, dependency management, build and deployment tools, and preferably a visual development and debugging environment. Only when these requirements are fully met can users better manage network traffic and the services carried on the traffic`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      